import React, { Component, useEffect, useState } from 'react';
import logo from '.././beach-sea.gif';
import '.././App.css';
import './create.css';
import Button from 'react-bootstrap/Button';


class Create extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cardNo: '',
      balance: 0.00,
      loading: true,
      cvv: '',
      exp: '',
      end: 'Your card has been issued. Click here to view',
      url: ''
    }



  }

  componentDidMount() {
    setTimeout(function(){
             this.setState({extra:'a'});
        }.bind(this),3000);
  }

  async componentWillMount() {


  const queryParameters = new URLSearchParams(window.location.search)
  const fname = queryParameters.get("fname")
  const lname = queryParameters.get("lname")
  const emailx = queryParameters.get("emailx")


    // POST request using fetch with async/await
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
   "Authentication":{
      "UserName":"YnXd9NWVAV",
      "Password":"ltlachfDth"
   },
   "CardHolderInfo":{
      "FirstName":fname,
      "MiddleName":null,
      "LastName":lname,
      "DateOfBirth":null,
      "Address1":null,
      "Address2":null,
      "City":null,
      "State":null,
      "Zip":null,
      "Province":null,
      "Phone":null,
      "CellPhone":null,
      "Country":null,
      "CallingCode":null,
      "EMail":emailx,
      "SSNumber":null,
      "DLNumber":null,
      "DLState":null,
      "DLExpDate":null,
      "ClientCustomerCode":null,
      "CustomerType":0,
      "IDUploadBase64String":null,
      "ProofOfAddressBase64String":null,
      "SelfieBase64String":null,
      "ClientKYC":0,
      "KYCRef":null,
      "CustomerEmploymentInfo":[
         {
            "CustomerEmployer":{
               "Name":null,
               "Address":null,
               "Address2":null,
               "City":null,
               "State":null,
               "Zip":null,
               "Country":null,
               "Phone":null,
               "Fax":null,
               "WebAddress":null,
               "CreditCode":null,
               "VerifiedFlag":null,
               "VerifiedId":null,
               "StateIncorporated":null,
               "DateIncorporation":null,
               "BusinessOpenDate":null,
               "BusinessType":null,
               "OtherBusinessType":null
            },
            "StartDate":null,
            "Email":null,
            "Occupation":null,
            "Supervisor":null,
            "JobType":null,
            "PayType":null,
            "Salary":null,
            "PayDay":null,
            "JobStatus":null,
            "CustomerEmployeeNumber":null
         },
         {
            "CustomerEmployer":{
               "Name":null,
               "Address":null,
               "Address2":null,
               "City":null,
               "State":null,
               "Zip":null,
               "Country":null,
               "Phone":null,
               "Fax":null,
               "WebAddress":null,
               "CreditCode":null,
               "VerifiedFlag":null,
               "VerifiedId":null,
               "StateIncorporated":null,
               "DateIncorporation":null,
               "BusinessOpenDate":null,
               "BusinessType":null,
               "OtherBusinessType":null
            },
            "StartDate":null,
            "Email":null,
            "Occupation":null,
            "Supervisor":null,
            "JobType":null,
            "PayType":null,
            "Salary":null,
            "PayDay":null,
            "JobStatus":null,
            "CustomerEmployeeNumber":"sample string 7"
         }
      ],
      "Gender":1,
      "Prefix":"sample string 25",
      "Suffix":"sample string 26"
   },
   "ExternalProgramId":50860
})
};


  //const getIP = await fetch('https://floating-tundra-75580.herokuapp.com/https://ipapi.co/json');
  //const ipData = await getIP.json();
  //console.log ("IP")
  //console.log(ipData)

    //https://tsbestcardsystems.net/GlobalCardServicesRestful/api/card/issue
    const response = await fetch('https://guarded-tundra-64602.herokuapp.com/https://floating-tundra-75580.herokuapp.com/create?fname=' + fname + '&lname=' + lname + '&emailx=' + emailx, requestOptions, {mode:'cors'});
    const data = await response.json();
    console.log("enrolls");
    //console.log({ postId: data.id });
    console.log(data);

    const customerID = data.CustomerId;

    //issueOptions.CustomerId = customerID;
    //console.log(issueOptions.CustomerId);

    var issueOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "Authentication": {
          "UserName":"YnXd9NWVAV",
          "Password":"ltlachfDth"
        },

    "CardNickName": null,
    "ClientCardTokenId": null,
    "CustomerId": customerID,
    "ExternalProgramId": 50860,
    "FundAmount": 0.00
    })
  };



    const response2 = await fetch('https://guarded-tundra-64602.herokuapp.com/https://floating-tundra-75580.herokuapp.com/issue?custID=' + customerID, issueOptions, {mode:'cors'});
    const data2 = await response2.json();
    console.log ("issue");
    console.log(data2);


    const cardtokenPass = data2.CardTokenId;

    this.setState({ cvv: data2.CVVNumber, exp: data2.ExpirationDate, cardNo: cardtokenPass })


    var fundOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "Authentication": {
          "UserName":"YnXd9NWVAV",
          "Password":"ltlachfDth"
        },
        "CardTokenId": cardtokenPass, // Response of 3.1
        "ClientCardTokenId": null,
        "ClientReferenceId": null,
        "Amount": 5.00
        })
  };

  const response3 = await fetch('https://guarded-tundra-64602.herokuapp.com/https://floating-tundra-75580.herokuapp.com/fund?cardtokenPass=' + cardtokenPass, fundOptions, {mode:'cors'});
  const data3 = await response3.json();
  console.log ("fund");
  console.log(data3);

  this.setState({ balance: 5.00 })

  var urlOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
      "Authentication": {
        "UserName":"YnXd9NWVAV",
        "Password":"ltlachfDth"
      },
      "CardTokenId": cardtokenPass, //Response of 3.1
      "ClientCardTokenId": null
    }
    )
};


  const response4 = await fetch('https://guarded-tundra-64602.herokuapp.com/https://floating-tundra-75580.herokuapp.com/displayURL?cardtokenPass=' + cardtokenPass, urlOptions, {mode:'cors'});
  const data4 = await response4.json();
  console.log ("displayURL");
  console.log(data4);

  this.setState({ url: data4.CardDisplayURL, loading: false })

  //if (this.state.url) {
    //this.setState({ loading: false })
  //}
  //else {
    //this.setState({ end: 'There was an error. Please try again!' })
  //}
}


  render() {
    return (

      <div className="App">
        <header className="App-header">

        <h1>Virtual Card</h1>
        <p>This may take up to 30 seconds...</p>

        <img src="https://media.tenor.com/WbNeimJ2BREAAAAi/beach-sea.gif" className="Loading-logo" alt="logo"/>

        <p>Here is your card token number. Please write this down and remember it, you will need it to check your balance!</p>
        <p>Card token: {this.state.cardNo}</p>

        <Button variant="primary" size="lg" className="ButtonCSS" href={"https://" + this.state.url}>
                  {this.state.loading ? 'Your card is being issued...' : this.state.end}
                </Button>{' '}



          <a
            className="App-link"
            href="https://www.telepath.clinic"
          >
            Visit telepath.clinic
          </a>
        </header>

      </div>

    );
  }
}

export default Create;
