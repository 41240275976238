import logo from './logo.svg';
import './App.css';
import Typef from "./components/typef";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/app.js";
import Create from "./pages/create.js";
import Balance from "./pages/balance.js";
import Test from "./pages/test.js";
import LayoutDefault from "./components/layoutDefault";

function App() {
  return (

    <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="create" element={<Create />} />
          <Route path="balance" element={<Balance />} />
          <Route path="test" element={<Test />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
