import React, { Component, useEffect, useState } from 'react';
import logo from '.././beach-sea.gif';
import '.././App.css';
import './create.css';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
const url = require('url');

//525260471154434

class Balance extends Component {

  constructor(props) {
    super(props)
    this.onChangeToken = this.onChangeToken.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      cardToken: '',
      balance: ''
    }
  }

  onChangeToken(e) {
        this.setState({ cardToken: e.target.value })
        console.log("********")
        console.log(this.state.cardToken)
        console.log("********")
    }

  async onSubmit(e) {

    e.preventDefault()
    // POST request using fetch with async/await
    const balanceOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "Authentication": {
          "UserName": "YnXd9NWVAV",
          "Password": "ltlachfDth"
           },
           "CardTokenId": this.state.cardToken,
           "ClientCardTokenId": null
         })
        };


    const response = await fetch('https://guarded-tundra-64602.herokuapp.com/https://floating-tundra-75580.herokuapp.com/balance?cardToken=' + this.state.cardToken, balanceOptions);

    const data = await response.json();
    //const data = response.json()
    console.log("Check Balance");
    console.log(data);
    this.setState({ balance: data.Balance })

  }

  async componentWillMount() {

}


  render() {
    return (

      <div className="App">
        <header className="App-header">

        <h1>Check Card Balance</h1>
        <p>Enter Your Card Token ID!</p>

        <form onSubmit={this.onSubmit}>
          <label>
            Card Token ID:
            <input className = "balanceInput" type="text" name="cardTokenId" value={this.state.cardToken} onChange={this.onChangeToken} />
          </label>
          <Button type="submit" variant="primary" value="Submit" size="lg" className="ButtonCSS">
                { "Check Balance" }
                  </Button>{' '}
        </form>

        <h1>Balance: {this.state.balance}</h1>

        <br />

          <a
            className="App-link"
            href="https://www.telepath.clinic"
          >
            Visit telepath.clinic
          </a>
        </header>

      </div>

    );
  }
}

export default Balance;
