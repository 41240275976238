import React, { Component, useEffect, useState } from 'react';
import logo from '.././beach-sea.gif';
import '.././App.css';
import './create.css';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
const url = require('url');
//const httpsProxyAgent = require('https-proxy-agent');

axios.defaults.baseURL = '103.156.141.100';

//525260471154434

class Test extends Component {


constructor(props) {
  super(props)
  this.onChangeToken = this.onChangeToken.bind(this);
  this.onSubmit = this.onSubmit.bind(this);
  this.state = {
    cardToken: '',
    balance: ''
  }
}

onChangeToken(e) {
      this.setState({ cardToken: e.target.value })
      console.log("********")
      console.log(this.state.cardToken)
      console.log("********")
  }

async onSubmit(e) {

  console.log("ertsrs")
  e.preventDefault()
  // POST request using fetch with async/await
  const balanceOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "Authentication": {
        "UserName": "YnXd9NWVAV",
        "Password": "ltlachfDth"
         },
         "CardTokenId": this.state.cardToken,
         "ClientCardTokenId": null
       })
      };


      //const getIP = await fetch('https://floating-tundra-75580.herokuapp.com/https://ipapi.co/json');
      //const ipData = await getIP.json();
      //console.log ("IP")
      //console.log(ipData)


  //https://tsbestcardsystems.net/GlobalCardServicesRestful/api/card/issue
  //const response = await fetch('https://floating-tundra-75580.herokuapp.com/https://bestcardsystems.com/GlobalCardServicesRestful/api/card/balance', balanceOptions);
  //537990862731853

  /*
  proxy: {
    protocol: 'http',
    host: fixieUrl.hostname,
    port: fixieUrl.port,
    auth: {username: fixieAuth[0], password: fixieAuth[1]}
  }

  proxy: {
    protocol: 'http',
    host: 'velodrome.usefixie.com',
    port: 80,
    auth: {username: 'fixie', password: '6Wtgw0kd4xJFIVy'}
  }


  */

  //console.log(process.env.FIXIE_URL)
  //console.log(process.env.REACT_APP_FIXIE_URL)

  const fixieUrl = url.parse(process.env.REACT_APP_FIXIE_URL);
  const fixieAuth = fixieUrl.auth.split(':');

  console.log (fixieUrl.hostname)
  console.log (fixieUrl.port)
  console.log (fixieAuth[0])
  console.log (fixieAuth[1]);


  axios.get('https://www.boredapi.com/api/activity', {
    //params: balanceOptions,
    proxy: {
      protocol: 'https',
      host: fixieUrl.hostname,
      port: fixieUrl.port,
      auth: {username: fixieAuth[0], password: fixieAuth[1]}
    }
  }).then(response => {
    //const data = response.json()
    //console.log("Check Balance");
    //console.log(data);

    //this.setState({ balance: data.Balance })
    console.log(response.status);
  });


//const response = await fetch('https://bestcardsystems.com/GlobalCardServicesRestful/api/card/balance', balanceOptions);

  //const data = await response.json();
  //const data = response.json()
  //console.log("Check Balance");
  //console.log(data);


}

async componentWillMount() {

}


render() {
  return (

    <div className="App">
      <header className="App-header">

      <h1>Check Card Balance</h1>
      <p>Enter Your Card Token ID!</p>

      <form onSubmit={this.onSubmit}>
        <label>
          Card Token ID:
          <input className = "balanceInput" type="text" name="cardTokenId" value={this.state.cardToken} onChange={this.onChangeToken} />
        </label>
        <Button type="submit" variant="primary" value="Submit" size="lg" className="ButtonCSS">
              { "Check Balance" }
                </Button>{' '}
      </form>

      <h1>Balance: {this.state.balance}</h1>

      <br />

        <a
          className="App-link"
          href="https://www.telepath.clinic"
        >
          Visit telepath.clinic
        </a>
      </header>

    </div>

  );
}
}

export default Test;
